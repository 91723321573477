import image1 from "../assets/t-image1.jpeg";
import image2 from "../assets/t-image2.jpeg";
import image3 from "../assets/t-image3.jpeg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Natural Fitness Club and by choosing the right plan and program I already achieved my ideal body!",
    name: 'HARISH RANE',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'I saved my life by reading the ingredients on the artificial protien shakes ',
    name: 'HARISH RANE',
    status: 'COACH'
  },
  {
    image : image3,
    review:'Achieving the fit body just by drinking artificial shakes or only workout,can you imagine growing a tree by giving only water and purning? ',
    name: 'HARISH RANE',
    status: "FOUNDER"
  }
];
