import React, { useState } from 'react'
import './Testimonials.css'
import { testimonialsData } from '../../data/testimonialsData'
import leftArrow from '../../assets/leftArrow.png'
import rightArrow from '../../assets/rightArrow.png'
const Testimonials = () => {
    const [selected , setSelected] = useState(0);
    const tlength = testimonialsData.length;
  return (
    <div className="Testimonials">
        <div className="left-t">
            <span>WHY NFC?</span>
            <span className='stroke-text'>WHAT OUR FOUNDER</span>
            <span>SAYS</span>
            <span>
                {testimonialsData[selected].review}
            </span>
            <span style={{color: 'var(--orange)'}}>
                {testimonialsData[selected].name}
            </span>{" "}
            - {testimonialsData[selected].status}
        </div>
        <div className="right-t">
            <div></div>
            <div></div>
            <img src={testimonialsData[selected].image} />

            <div className="arrows">
                <img 
                onClick={()=>{
                    selected===0?setSelected(tlength-1):setSelected((prev) => prev-1);
                }}
                
                src={leftArrow} />
                <img 
                onClick={()=>{
                selected===tlength-1?setSelected(0):setSelected((prev)=>prev+1);

                }
                

                }
                
                src={rightArrow}/>
            </div>
        </div>
    </div>
  )
}

export default Testimonials

